<template>
    <div class="base-date">
        <div class="sr-only">
            <time v-if="startDate" :datetime="lightFormat(new Date(startDate), 'yyyy-MM-dd')">
                {{ formattedStartDate }}
            </time>
            <template v-if="endDate">
                <span>{{ $t('a11y.until') }}</span>
                <time :datetime="lightFormat(new Date(endDate), 'yyyy-MM-dd')">
                    {{ formattedEndDate }}
                </time>
            </template>
        </div>
        <div class="base-date__visible" aria-hidden="true">
            <template v-if="startDate && endDate">
                {{ formattedRange }}
            </template>
            <template v-else>
                {{ formattedStartDate }}
            </template>
        </div>
    </div>
</template>

<script>
import { lightFormat, parseISO, isSameMonth, getDate } from 'date-fns';

export default {
    props: {
        startDate: {
            type: String,
            required: true,
            default: ''
        },
        endDate: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        formattedStartDate() {
            return this.$d(parseISO(this.startDate), 'dateShort').replace('.', '');
        },
        formattedEndDate() {
            return this.$d(parseISO(this.endDate), 'dateShort').replace('.', '');
        },
        formattedRange() {
            const sameMonth = isSameMonth(new Date(this.startDate), new Date(this.endDate));

            if (sameMonth) {
                return `${getDate(new Date(this.startDate))} - ${this.formattedEndDate}`;
            } else {
                return `${this.formattedStartDate} - ${this.formattedEndDate}`;
            }
        }
    },
    methods: {
        lightFormat, parseISO
    },
};
</script>
